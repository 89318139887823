import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import '../styles/style.scss';
import Header from './header'
import Footer from './footer';

const Layout = ({children, title}) => (
    <>
        <Helmet
            title={title}
            meta={[
                {name: 'description', content: title},
                {name: 'keywords', content: title},
            ]}>
        </Helmet>

        <section className="section-main">
                {children}
            <Footer/>
        </section>
    </>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
