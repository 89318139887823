import React from 'react'
import Layout from "../components/layout-v2";
import '../styles/terms.scss'

const TermsOfService = () => (
    <Layout>
        <div className='section-cookies'>
            <div className="terms-of-use">
                <div className='heading'>
                    <h2>General Terms of Service</h2>
                    </div>

                <div className='content'>
                    <p>These General Terms of Service ("General Terms") are a legal agreement between you, as a current or prospective customer of DD Zone's services ("you", "your") and DD Zone, Inc., ("DD Zone", "we", "our" or "us") and govern your use of DD Zone's services, including mobile applications, websites, software, cloud-based solutions, hardware, and other products and services (collectively, the "Services"). By using any of the Services, you agree to these General Terms and any policies referenced within ("Policies"), including our Privacy Policy and terms that limit our liability and require individual arbitration for any potential legal dispute, which are collectively incorporated herein by reference. You also agree to any additional terms specific to Services you use ("Additional Terms"), such as those listed below, which become part of your agreement with us (collectively, the "Terms"). If you are using the Services on behalf of a business, you represent to us that you have authority to bind that business or entity to these Terms, and that business accepts these Terms. You should read all of our Terms carefully. </p>
                </div>

                <div className='content'>
                    <h3>1. Account</h3>
                    <p>You must open an account with us (a "DD Zone Account") to use the Services. During registration we will ask you for information, which may include but is not limited to, your name and other personal information. You must provide accurate and complete information in response to our questions, and you must keep that information current. You are fully responsible for all activity that occurs under your DD Zone Account, including for any actions taken by persons to whom you have granted access to the DD Zone Account. We reserve the right to change the account type, suspend or terminate the DD Zone Account of anyone who provides inaccurate, untrue, or incomplete information, or who fails to comply with the account registration requirements. </p>
                </div>

                <div className='content'>
                    <h3>2. Restrictions</h3>
                    <p>You acknowledge and agree that the Services are not a marketplace, and any contract of sale made through the Services is directly between you and the customer. You are the seller of record for all items you sell through the Services. </p>
                    <p>You are responsible for the operation of your DD Zone Store, your Materials, the goods and services that you may sell through the Services, and all aspects of the transactions between you and your customer(s). This includes, but is not limited to, authorizing the charge to the customer in respect of the customer's purchase, refunds, returns, fulfilling any sales or customer service, fraudulent transactions, required legal disclosures, regulatory compliance, alleged or actual violation of applicable laws (including but not limited to consumer protection laws in any jurisdiction where you offer products or services for sale), or your breach of these Terms of Service. </p>
                    <p>You represent and warrant that your Store, your Materials and the goods and services you sell through the Services will be true, accurate, and complete, and will not violate any applicable laws, regulations or rights of third parties. For the avoidance of doubt, DD Zone will not be the seller or merchant or record and will have no responsibility for your Store or items sold to customers through the Services. </p>
                    <span>
                        <p><strong>You may not, nor may you permit any third party, directly or indirectly, to: </strong></p>
                        <ul>
                            <li>Engage in any activity that may be in violation of regulations administered by the United States Department of the Treasury's Office of Foreign Asset Control (31 C.F.R. Parts 500-599). If found to be in apparent violation of these restrictions, your account could be terminated and your funds could be held for an indefinite period of time. </li>
                            <li>Access or monitor any material or information on any DD Zone system using any manual process or robot, spider, scraper, or other automated means. </li>
                            <li>Except to the extent that any restriction is expressly prohibited by law, violate the restrictions in any robot exclusion headers on any Service, work around, bypass, or circumvent any of the technical limitations of the Services, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services. </li>
                            <li>Perform or attempt to perform any actions that would interfere with the proper working of the Services, prevent access to or use of the Services by our other customers, or impose an unreasonable or disproportionately large load on our infrastructure. </li>
                            <li>Copy, reproduce, alter, modify, create derivative works, publicly display, republish, upload, post, transmit, resell or distribute in any way material, information or Services from DD Zone. </li>
                            <li>Use and benefit from the Services via a rental, lease, timesharing, service bureau or other arrangement. </li>
                            <li>Transfer any rights granted to you under these General Terms. </li>
                            <li>Use the Services for any illegal activity or goods or in any way that exposes you, other DD Zone users, our partners, or DD Zone to harm, or</li>
                            <li>Otherwise use the Services except as expressly allowed under these Terms. </li>
                        </ul>
                        <p>If we reasonably suspect that your DD Zone Account has been used for an unauthorized, illegal, or criminal purpose, you give us express authorization to share information about you, your DD Zone Account, and any of your transactions with law enforcement. </p>
                    </span>
                </div>

                <div className='content'>
                    <h3>3. Content</h3>
                    <p>The Services may include functionality for uploading or providing suggestions, recommendations, feedback, stories, photos, documents, logos, products, loyalty programs, promotions, advertisements and other materials or information ("Content"). </p>
                    <p>You grant us and our subsidiaries, affiliates, and successors a worldwide, non-exclusive, royalty-free, fully-paid, transferable, irrevocable, perpetual, and sub-licensable right to use, reproduce, modify, adapt, publish, prepare derivative works of, distribute, publicly perform, and publicly display your Content throughout the world in any media for any reason, including to provide, promote, and/or incorporate into the Services. You retain all rights in your Content, subject to the rights you granted to us in these General Terms. You may modify or remove your Content via your DD Zone Account or by terminating your DD Zone Account, but your Content may persist in historical, archived or cached copies and versions thereof available on or through the Services. </p>
                    <span>
                        <p><strong>You will not upload or provide Content or otherwise post, transmit, distribute, or disseminate through the Services any material that: </strong></p>
                        <ul>
                            <li>Is false, misleading, unlawful, obscene, indecent, lewd, pornographic, defamatory, libelous, threatening, harassing, hateful, abusive, or inflammatory. </li>
                            <li>Encourages conduct that would be considered a criminal offense or gives rise to civil liability. </li>
                            <li>Breaches or infringes any duty toward or rights of any person or entity, including rights of publicity, privacy or Intellectual Property Rights. </li>
                            <li>Contains corrupted data or any other harmful, disruptive, or destructive files. </li>
                            <li>In our sole judgment, is objectionable, restricts or inhibits any person or entity from using or enjoying any portion of the Services, or which may expose DD Zone, its affiliates or its customers or other persons to harm or liability of any nature. </li>
                        </ul>
                        <p>
                            Although we have no obligation to monitor any Content, we have absolute discretion to remove Content at any time and for any reason without notice. DD Zone may also monitor such Content to detect and prevent fraudulent activity or violations of DD Zone's General Terms. You understand that by using the Services, you may be exposed to Content that is offensive, indecent, or objectionable. We take no responsibility and assume no liability for any Content, including any loss or damage to any of your Content.
                        </p>
                    </span>
                </div>

                <div className='content'>
                    <h3>4. Security</h3>
                    <p>We have implemented technical and organizational measures designed to secure your personal information from accidental destruction, loss, alteration and from unauthorized access, use, alteration, or disclosure. However, we cannot guarantee that unauthorized third parties will never be able to defeat those measures or use your personal information for improper purposes. You provide your personal information at your own risk. </p>
                    <p>You are solely responsible for safeguarding your password and for restricting access to the Services from your compatible mobile devices and computer(s). You will immediately notify us of any unauthorized use of your password or DD Zone Account or any other breach of security. You will immediately take all reasonable steps to mitigate the effects of a security breach and will cooperate with DD Zone and provide all information requested by DD Zone to remediate the breach. Any assistance provided by DD Zone in relation to a security breach does not in any way operate as acceptance or acknowledgement that DD Zone is in any way responsible or liable to you or any other party in connection with such breach. </p>
                </div>

                <div className='content'>
                    <h3>5. Communication</h3>
                    <p>You consent to accept and receive communications from us, including e-mail, text messages, calls, and push notifications to the cellular telephone number you provide to us when you sign-up for a DD Zone account or update the contact information associated with your account. Such communications may include, but are not limited to requests for secondary authentication, receipts, reminders, notifications regarding updates to your account or account support, and marketing or promotional communications. You acknowledge that you are not required to consent to receive promotional texts or calls as a condition of using the Services. Call and text message communications may be generated by automatic telephone dialing systems. Standard message and data rates applied by your cell phone carrier may apply to the text messages we send you. </p>
                </div>

                <div className='content'>
                    <h3>6. Modification and Termination</h3>
                    <p>We may terminate these General Terms or any Additional Terms, or suspend or terminate your DD Zone Account or your access to any Service, at any time for any reason. We may add or remove, suspend, stop, delete, discontinue or impose conditions on Services or any feature or aspect of a Service. We will take reasonable steps to notify you of termination or these other types of Service changes by email or at the next time you attempt to access your DD Zone Account. You may also terminate the General Terms and Additional Terms applicable to your DD Zone Account by deactivating your DD Zone Account at any time. </p>
                </div>

                <div className='content'>
                    <h3>7. Effect of Termination</h3>
                    <span>
                        <p><strong>If these General Terms or your DD Zone Account is terminated or suspended for any reason: </strong></p>
                        <ul>
                            <li>The license and any other rights granted under these Terms will end,</li>
                            <li>You agree to immediately terminate and cease use of all Services,</li>
                            <li>We may (but have no obligation to) delete your information and account data stored on our servers, and</li>
                            <li>We will not be liable to you or any third party for compensation, reimbursement, or damages for any termination or suspension of the Services, or for deletion of your information or account data.</li>
                        </ul>
                    </span>
                </div>

                <div className='content'>
                    <h3>8. License</h3>
                    <p>We grant you a limited, non-exclusive, revocable, non-transferable, non-sublicensable license to use the Paid Services, and a royalty-free, limited, non-exclusive, revocable, non-transferable, non-sublicensable license to use the Free Services as authorized in these General Terms. We may make updates to the Services available to you, which you must accept to continue using the Services. Any such updates may be subject to additional terms made known to you at that time. </p>
                </div>

                <div className='content'>
                    <h3>9. Representations and Warranties</h3>
                    <span>
                        <p><strong>You represent and warrant to us that: </strong></p>
                        <ul>
                            <li>You are at least eighteen (18) years of age,</li>
                            <li>You are eligible to register and use the Services and have the right, power, and ability to enter into and perform under these General Terms,</li>
                            <li>Any information you provide in connection with the Services, including your business name, accurately and truthfully represents your business or personal identity under which you sell goods and services,</li>
                            <li>You and all transactions initiated by you will comply with all federal, state, and local laws, rules, and regulations applicable to you and/or your business, including the Health Insurance Portability and Accountability Act (“HIPAA”),</li>
                            <li>You will not use the Services, directly or indirectly, for any fraudulent undertaking or in any manner so as to interfere with the operation of the Services, and</li>
                            <li>Your use of the Services will be in compliance with these Terms.</li>
                        </ul>
                    </span>
                    <span>
                        <p><strong>DD Zone does not warrant, represent or guarantee in any way: </strong></p>
                            <ul>
                                <li>that the services are accurate, reliable or correct</li>
                                <li>that the services will meet your requirements</li>
                                <li>that the services will be available at any particular time or location, uninterrupted, error-free, without defect or secure</li>
                                <li>that any defects or errors in the services will be corrected</li>
                                <li>that the services are free of viruses or other harmful components or fit for any particular purpose.</li>
                            </ul>
                    </span>
                    <p>DD Zone does not warrant, endorse, guarantee, or assume responsibility or liability for any product or service advertised or offered by a third party.</p>
                </div>

                <div className='content'>
                    <h3>10. Third Party Services</h3>
                    <p>You may be offered services, products and promotions provided by third parties and not by DD Zone, including, but not limited to, third party developers who use DD Zone’s services (“Third Party Services”). If you decide to use Third Party Services, you will be responsible for reviewing and understanding the terms and conditions for these services. We are not responsible or liable for the performance of any Third Party Services. Further, you agree to resolve any disagreement between you and a third party regarding the terms and conditions of any Third Party Services with that third party directly in accordance with the terms and conditions of that relationship, and not DD Zone. The Services may contain links to third party websites. The inclusion of any website link does not imply an approval, endorsement, or recommendation by DD Zone. Such third party websites are not governed by these General Terms. You access any such website at your own risk. We expressly disclaim any liability for these websites. When you use a link to go from the Services to a third party website, our Privacy Policy is no longer in effect. Your browsing and interaction on a third party website, including those that have a link in the Services is subject to that website’s own terms, rules and policies. </p>
                </div>

            </div>
        </div>
    </Layout>
);

export default TermsOfService
